/* HEADER */
.karirHead {
  background-image: linear-gradient(to right, #ffd700, #d9d9d9);
  padding: 30px 15px;
}
.karirHead hr {
  border-color: white;
  width: 35px;
}
.karirHead p {
  font-size: 14px;
  margin-bottom: 4px;
}
.karirHead h1 {
  font-size: 40px;
}

/* BODY */
.karirBody {
  padding: 30px 15px;
}

@media screen and (min-width: 1000px) {
  .karirHead {
    padding: 30px 150px;
  }
  .karirBody {
    padding: 50px 150px;
  }
}

.karirBody .card {
  border: 0;
  border-radius: 20px;
  box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.15);
  padding: 25px;
}

.karirBody p {
  margin: 0;
}

.karirBody .card h4 {
  margin: 0 0 10px 0;
  height: 2.5em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.karirBody .card p {
  margin: 0;
  color: #727272;
}

.karirBody .card img {
  width: 24px;
  height: auto;
  aspect-ratio: 1;
  margin: 2.5px 5px 2.5px 0;
}

.karirBody .card button {
  margin-top: 10px;
  width: 100%;
}

.karirBody ol {
  padding-left: 16px;
}
