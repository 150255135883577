/* HEADER */
.galeriHead {
  background-color: #ffd700;
  padding: 30px 15px;
}
.galeriHead hr {
  border-color: white;
  width: 35px;
}
.galeriHead p {
  font-size: 14px;
  margin-bottom: 4px;
}
.galeriHead h1 {
  font-size: 40px;
}

/* BODY */
.galeriBody {
  padding: 30px 15px;
}

@media screen and (min-width: 1000px) {
  .galeriHead {
    padding: 30px 150px;
  }
  .galeriBody {
    padding: 50px 150px;
  }
}

.galeriBody .card {
  height: 220;
  aspect-ratio: 16/11;
  background-size: cover;
  background-position: center;
  border: 0;
  border-radius: 15px;
  overflow: hidden;
}

.galeriBody .card .info {
  background-image: linear-gradient(to top, black, 50%, rgba(0, 0, 0, 0));
  height: 90%;
  color: white;
  padding: 15px 20px;
}

.galeriBody .card .info p {
  font-size: 13px;
  margin: 0;
}

.galeriBody .card .info button {
  background-color: rgba(0, 0, 0, 0);
  color: #ffd700;
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
}

#seeMore {
  padding: 5px 15px 5px 25px;
}

/* GALERI DETAIL */
#thumbMain,
.secondary img,
.third img {
  border: 0;
  border-radius: 20px;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
}
