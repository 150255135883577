.header {
  font-weight: bolder;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}

/* CONTACT */
.contact {
  background-color: #ffd700;
}

.contact p {
  margin: 0;
  line-height: 30px;
  font-size: small;
}

/* responsive contact fonts */
@media screen and (max-width: 576px) {
  .contact p {
    line-height: 15px;
  }
}

.contact * {
  align-items: center;
}

.contact img {
  margin: 0 5px;
}

/* NAVBAR */
.navbar {
  background-color: #fff;
}

.navbar img {
  width: 70%;
  min-width: 175px;
  max-width: 300px;
  padding: 10px;
}

.navbar a,
.navbar a:link,
.navbar a:visited {
  color: black;
  text-decoration: none;
  padding: 0 8px;
  font-size: medium;
  white-space: nowrap;
}

@media screen and (max-width: 1400px) {
  .navbar li {
    padding: 5px 0;
  }
  .navbar .dropdown-toggle {
    padding: 5px 2px !important;
  }
  .dropdown {
    padding: 0 !important;
    border-radius: 5px;
  }
}

li:has(.active) {
  background-color: black;
  border-radius: 3px;
}

.active {
  color: #ffd700 !important;
  background-color: black !important;
}

li:has(a:hover) {
  background-color: grey;
}

a:hover {
  color: white !important;
  background-color: grey !important;
}

.dropdown-toggle {
  padding: 0 !important;
  margin: 0 5px !important;
}

.dropdown:has(.active) > .dropdown-toggle {
  color: #ffd700;
}

.show {
  padding: 0;
  border-radius: 0 0 5px 5px;
  border-color: lightgray;
}

.dropdown-menu a {
  border-radius: 5px;
  padding: 0 5px;
}

@media screen and (min-width: 1400px) {
  /* .navbar-collapse,
  ul,
  li {
    height: 70px;
  }
  li > a {
    line-height: 70px;
  } */
  .dropdown-toggle {
    height: 70px;
    margin: 0 !important;
  }

  .navbar-nav > * {
    height: 70px;
    line-height: 70px;
    padding: 0px 10px;
  }

  .show > * {
    height: fit-content;
    line-height: 3em;
  }
}
