.highlight {
  margin: 25px 25px 0 25px;
}

.others {
  margin: 0 25px 25px 25px;
}

@media screen and (min-width: 1000px) {
  .highlight {
    margin: 50px 150px 0 150px;
  }

  .others {
    margin: 0 150px 50px 150px;
  }
}

.highlight p {
  margin: 0;
}

/* MAIN HIGHLIGHT */
.highlight .main .thumb {
  width: 100%;
  aspect-ratio: 2/1;
  border-radius: 20px;
  object-fit: cover;
  object-position: center;
}

.highlight .main h1 {
  margin: 5px 0;
}

.category {
  background-color: #ffd700;
  border-radius: 6px;
  padding: 4px 15px;
  font-weight: 700;
}

.time {
  font-weight: 400;
  color: #727272;
  margin: 0 10px;
}

.comment {
  color: #727272;
  font-weight: 400;
}

.time img,
.comment img {
  margin-right: 5px;
}

/* "Responsive sizing" */
@media screen and (min-width: 500px) {
  .highlight .main h1 {
    font-size: 32px;
  }
  .category,
  .time,
  .comment {
    font-size: 20px;
  }
}

/* SECONDARY HIGHLIGHT */
.highlight .secondary .thumb {
  border-radius: 15px;
  margin-right: 5px;
  width: 40%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
}

.highlight .secondary .category {
  font-size: 16px;
  width: fit-content;
}

.highlight .secondary h5 {
  margin: 5px 0 0 0;
  padding: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.highlight .secondary .time,
.highlight .secondary .comment {
  font-size: 16px;
  text-wrap: nowrap;
}

.highlight .secondary .time {
  margin: 0 5px 0 0;
}

.highlight .secondary .row > * {
  height: max-content;
  margin: 10px 0;
}

/* OTHERS */
.others .section {
  margin: 40px 0;
}

.others .section hr {
  width: 5%;
}

/* CARD */
.others .card {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.1);
}

.others .card img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 3/2;
}

.others .card .info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 15px;
}

.others .card .info h6 {
  margin: 0;
}

.others .card .info .time {
  font-size: 14px;
  margin: 0;
}

.others .card .info button {
  font-size: 16px;
  margin-top: 15px;
}
.others .see {
  padding: 0;
  width: max-content;
  background-color: rgba(0, 0, 0, 0);
}
