.carousel h1,
.testimony h1,
.news h1 {
  font-size: 36px;
}
/* CAROUSEL */
.carousel {
  padding: 20px 0;
}
@media screen and (min-width: 1000px) {
  .carousel {
    padding: 50px 150px 30px 150px;
  }
}
.swiper {
  margin: 0 0 10px 0 !important;
}
.swiperPhoto {
  width: 80%;
  max-width: 628px;
  aspect-ratio: 628/431;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
}
.swiperDesc {
  width: 80%;
  overflow: hidden;
  margin-bottom: 20px;
}
.carousel p {
  text-align: justify;
}
@media screen and (min-width: 576px) {
  .swiperPhoto {
    width: 50%;
    margin: 0 10px 0 0 !important;
  }
  .carousel {
    padding: 20px 60px;
  }
}
@media screen and (min-width: 768px) {
  .swiperPhoto {
    width: 50%;
    margin: 10px !important;
  }
  .swiperDesc {
    width: 30%;
    overflow: hidden;
    margin: 10px !important;
  }
}
.swiperPhoto img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/* TESTIMONY */
.testimony {
  padding: 0 60px 20px 60px;
}
@media screen and (min-width: 1000px) {
  .testimony {
    padding: 0 150px 30px 150px;
  }
}
.testimony .cards {
  display: flex;
  flex-direction: column;
  border: 0;
  box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.1);
  height: 324px;
  padding: 40px 25px;
  border-radius: 20px;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  margin: 15px;
}
.testimony .cards hr {
  margin: 3px 3px 10px 3px;
}
.testimony .cards img {
  height: 86px;
  aspect-ratio: 1/1;
  border-radius: 100%;
}
.testimony .cards h4 {
  margin: 10px 0;
}
/* NEWS */
.news {
  padding: 0 60px 20px 60px;
}
@media screen and (min-width: 1000px) {
  .news {
    padding: 0 150px 30px 150px;
  }
}
.news img {
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
  object-position: center;
}
.news .cards {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 240px;
  aspect-ratio: 7/8;
  border-radius: 20px;
  box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 15px;
}
.news .cards .info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}
.title {
  font-weight: 700;
  margin: 0;
}
.news .cards button {
  font-weight: bolder;
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
  background-color: #ffd700;
}
