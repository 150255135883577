/* HEADER */
.kontakHead {
  background-color: #ffd700;
  padding: 30px 15px;
}
.kontakHead hr {
  border-color: white;
  width: 35px;
}
.kontakHead p {
  font-size: 14px;
  margin-bottom: 4px;
}
.kontakHead h1 {
  font-size: 40px;
}

/* BODY */
.kontakBody {
  padding: 30px 15px;
}

@media screen and (min-width: 1000px) {
  .kontakHead {
    padding: 20px 150px;
  }
  .kontakBody {
    padding: 50px 150px;
  }
}

.kontakBody hr {
  width: 35px;
}

.location {
  aspect-ratio: 11/9;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
  border: 0;
}

.kontakBody h5 {
  margin: 10px 0;
}

.kontakBody p {
  margin: 0;
}

.kontakBody .info > * {
  margin: 5px 0;
}

.kontakBody .info img {
  margin-right: 5px;
}

.kontakBody form textarea {
  height: 150px;
}

.kontakBody h2 {
  padding: 0;
}

.kontakBody .form {
  margin: 50px 0;
}
