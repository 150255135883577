.thumb {
  height: 300px;
  overflow: hidden;
}

.thumb img {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
}

#category {
  font-size: 14px;
  color: #727272;
}

.news {
  margin: 20px 15px;
}

@media screen and (min-width: 1000px) {
  .news {
    margin: 20px 50px;
  }
}

.news h2,
h4 {
  margin: 15px 0;
}

.news p {
  margin: 0;
}

.news .detail p {
  font-size: 16px;
  text-wrap: nowrap;
}

.news .detail img {
  margin-right: 5px;
}

#report {
  margin: 25px 0;
}

form {
  padding-top: 15px;
}

#inputKomentar {
  height: 125px;
}

.sec2 hr {
  width: 35px;
  margin: 10px 0;
}

.sec2 p {
  margin: 10px 0;
  font-size: 14px;
}

.sec2 > * {
  margin: 30px 0;
}

.sec2 > :first-child {
  margin-top: 0;
}

@media screen and (min-width: 1000px) {
  .sec1 {
    padding-right: 100px;
  }
  .sec2 {
    padding-left: 50px;
  }
}
