@font-face {
  font-family: "Candara";
  src: url("../src//assets/Candara.woff2") format("woff2"),
    url("../src/assets/Candara.woff") format("woff");
}
html * {
  font-family: Candara;
}
hr {
  border: 2px solid #ffd700;
  opacity: 1;
  width: 35px !important;
  margin-top: 4px;
  border-radius: 2px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

/* SWIPER */
.swiper {
  --swiper-pagination-color: black;
  --swiper-navigation-color: black;
}

.swiper-button-prev {
  background-image: url("./assets/arrow.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  rotate: 90deg;
}

.swiper-button-next {
  background-image: url("./assets/arrow.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  rotate: -90deg;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "" !important;
}

.content {
  z-index: 1;
}
