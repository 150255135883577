/* HEADER */
.prodHeader {
  background-image: linear-gradient(to right, #ffd700, #d9d9d9);
  padding: 30px 15px;
}

.prodHeader hr {
  border-color: white;
}

.prodHeader p {
  font-size: 14px;
  margin-bottom: 4px;
}

.prodHeader h1 {
  font-size: 40px;
}

/* DESC */
.prodDesc {
  padding: 30px 15px;
}

.prodDesc ul,
li {
  height: fit-content;
}

.prodDesc hr {
  margin: 10px 0;
}

@media screen and (min-width: 992px) {
  .prodDesc .wrap1 {
    width: 50%;
    padding: 0 10px 0 0;
  }

  .prodDesc .wrap2 {
    width: 50%;
    padding: 0 0 0 10px;
  }
}

@media screen and (min-width: 1000px) {
  .prodHeader {
    padding: 40px 150px;
  }

  .prodDesc {
    padding: 40px 150px;
  }
}
