/* HEADER */
.tentangHeader {
  background-image: linear-gradient(to right, #ffd700, #d9d9d9);
  padding: 30px 15px;
}
.tentangHeader hr {
  border-color: white;
}
.tentangHeader p {
  font-size: 14px;
  margin-bottom: 4px;
}
.tentangHeader h1 {
  font-size: 40px;
}
/* DESC */
.tentangDesc {
  padding: 30px 15px;
}
.tentangDesc ul,
li {
  height: fit-content;
}
.tentangDesc hr {
  margin: 10px 0;
}
#bprInline {
  float: left;
  width: 179px;
  height: 237px;
  margin: 0 27px 27px 0;
}
@media screen and (min-width: 992px) {
  .tentangDesc .wrap1 {
    width: 50%;
    padding: 0 10px 0 0;
  }
  .tentangDesc .wrap2 {
    width: 50%;
    padding: 0 0 0 10px;
  }
}

@media screen and (min-width: 1000px) {
  .tentangHeader {
    padding: 40px 150px;
  }
  .tentangDesc {
    padding: 40px 150px;
  }
}

/* VISI MISI */
.visiMisi h1 {
  font-size: 48px;
  line-height: 48px;
}

/* TESTIMONI */
.testimoni .card {
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 0 6px lightgray;
  height: 100%;
}
.testimoni .card img {
  border-radius: 100%;
  width: 86px;
  height: 86px;
  aspect-ratio: 1/1;
  margin-right: 10px;
}
.testimoni .card p {
  margin: 0;
}
.testimoni .card hr {
  margin: 3px 0 5px 0;
}
.testimoni .card h4 {
  margin: 0 0 5px 0;
}
.testimoni .cards {
  width: 100%;
  margin: 0;
}
.testimoni .swiper {
  padding-bottom: 30px;
}
.testimoni .swiperSlide {
  padding: 0;
}
.testimoni .swiper .swiperButtonPrev {
  width: 20px;
  aspect-ratio: 1/1;
  background-image: url("../../assets/arrow.svg");
  rotate: 90deg;
}
.testimoni .swiper .swiperButtonNext {
  width: 20px;
  aspect-ratio: 1/1;
  background-image: url("../../assets/arrow.svg");
  rotate: -90deg;
}
.testimoni .swiper .swiperPagination {
  width: auto !important;
  margin: 0;
}

/* PENGHARGAAN */
.penghargaanContainer {
  width: 100%;
}
.penghargaan {
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 15px;
}
.penghargaan h6,
p {
  margin: 0;
}
.penghargaanToggler {
  width: 100%;
  background-color: #ffd700;
  align-items: center;
  padding: 10px;
}
.penghargaanItem {
  padding: 10px;
  border: 2px solid #ffd700;
  border-top: none;
}
#penghargaanImg {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
