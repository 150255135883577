.footer {
  background-color: #f5f5f5;
  padding: 40px 20px 30px 20px;
}

.footer > *,
.wrap-1 > *,
.wrap-2 > * {
  margin: 0 10px;
}

/* LOGOS */
.logos p {
  margin: 0;
  font-size: 10px;
}
.logos img {
  margin-bottom: 10px;
}
.footer .logos #logobpr {
  width: 200px;
}
.footer .logos #logoojk,
#logolps {
  width: 100px;
}
.footer .logos #logobpr2 {
  width: 60px;
}

.footer h5 {
  font-weight: bolder;
  margin: 0;
}

/* LINKS */
.footer .links a,
.footer .links a:active {
  text-decoration: none;
  color: black;
  font-size: 12px;
  background-color: none;
}

.footer .text-bold {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
}
.footer .text {
  font-size: 11px;
}

/* CONTACTS */
.footer .contacts {
  margin-bottom: 10px;
}

.footer .contacts .other a {
  font-size: 12px;
  color: black;
  line-height: 12.5px;
}
.footer .contacts .other img {
  width: 13px;
  height: 13px;
}
.footer .logos,
.footer .links,
.footer .contacts {
  margin-bottom: 20px;
}
@media screen and (min-width: 950px) {
  .footer .logos,
  .footer .links,
  .footer .contacts {
    margin-right: 50px;
  }
}
@media screen and (min-width: 1200px) {
  .footer .logos,
  .footer .links,
  .footer .contacts {
    margin-right: 75px;
  }
}
@media screen and (min-width: 1600px) {
  .footer .logos,
  .footer .links,
  .footer .contacts {
    margin-right: 150px;
  }
}
