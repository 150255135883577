.content {
  padding: 0;
}
.content hr {
  width: 10%;
  border-width: 3px;
}
/* HEADER */
.prod-header {
  background-image: linear-gradient(to right, #ffd700, #d9d9d9);
  padding: 30px 15px;
}
.prod-header hr {
  border-color: white;
}
.prod-header p {
  font-size: 14px;
  margin-bottom: 4px;
}
.prod-header h1 {
  font-size: 40px;
}
/* CONTENT */
.prod-desc {
  padding: 30px 15px;
}
button {
  font-weight: bold;
  border: 0;
  border-radius: 5px;
  padding: 5px 25px;
  background-color: #ffd700;
}
.prod-desc .wrap-2 {
  border-radius: 5px;
}
@media screen and (min-width: 992px) {
  .prod-desc .wrap-1 {
    width: 50%;
  }
  .prod-desc .wrap-2 {
    width: 40%;
    margin-left: 50px;
  }
}

@media screen and (min-width: 1000px) {
  .prod-header {
    padding: 40px 150px;
  }

  .prod-desc {
    padding: 40px 150px;
  }
}
