/* HEADER */
.syaratHeader {
  background-image: linear-gradient(to right, #ffd700, #d9d9d9);
  padding: 30px 15px;
}
.syaratHeader hr {
  border-color: white;
}
.syaratHeader p {
  font-size: 14px;
  margin-bottom: 4px;
}
.syaratHeader h1 {
  font-size: 40px;
}
/* DESC */
.syaratDesc {
  padding: 30px 15px;
}

@media screen and (min-width: 1000px) {
  .syaratHeader {
    padding: 40px 150px;
  }
  .syaratDesc {
    padding: 40px 150px;
  }
}
